import { generatePath } from "react-router-dom";
import { toast } from "react-toastify";
import StorageService from "../services/storage.service";

/**
 * Displays a success notification with the given message.
 *
 * @param {string} message - The message to display in the notification.
 */
export const successNotification = (message) =>
  toast.success(message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

/**
 * Displays an error notification with the given message.
 *
 * @param {string} message - The message to display in the notification.
 */
export const errorNotification = (message) =>
  toast.error(message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

/**
 * Displays an warning notification with the given message.
 *
 * @param {string} message - The message to display in the notification.
 */
export const warningNotification = (message) =>
  toast.warning(message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

/**
 * Finds the maximum powers of two that sum up to the given number.
 *
 * @param {number} number - The number to decompose into powers of two.
 * @returns {number[]} An array of the maximum powers of two that sum up to the given number.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const findMaxPowersOfTwo = (number) => {
  let powers = [];
  let power = Math.floor(Math.log2(number));

  while (number > 0) {
    if (Math.pow(2, power) <= number) {
      powers.push(Math.pow(2, power));
      number -= Math.pow(2, power);
    }
    power--;
  }

  if (powers[powers.length - 1] === 1) {
    powers.pop();
  }

  return powers;
};

/**
 * Finds the maximum numbers from the given array that sum up to a value less than or equal to the target.
 * The numbers are sorted in descending order before selection.
 *
 * @param {number} target - The target sum that should not be exceeded.
 * @param {number[]} numbers - The array of numbers to select from.
 * @returns {number[]} An array of numbers that sum up to a value less than or equal to the target.
 */
function maxNumbersBelowTarget(target, numbers) {
  numbers.sort((a, b) => b - a);

  let result = [];
  let total = 0;

  for (let num of numbers) {
    if (total + num <= target) {
      result.push(num);
      total += num;
    }
  }

  return result;
}

/**
 * Extracts roles from a given roleId by finding the maximum numbers below the target roleId.
 *
 * @param {number} roleId - The role ID to extract roles from.
 * @param {Array<Object>} [allRoles=[]] - An array of all possible roles, each role should have an `id` property.
 * @returns {Array<number>} - An array of role IDs that are the maximum numbers below the target roleId.
 */
export const extractRolesFromRoleId = (roleId, allRoles = []) => {
  // const possibleRoles = findMaxPowersOfTwo(roleId);
  let allRoleIds = allRoles.map((role) => role?.id);
  // return possibleRoles.filter((role) => allRoleIds?.includes(role));

  return maxNumbersBelowTarget(roleId, allRoleIds);
};

/**
 * Generates a URL path by replacing the username in the core URL.
 *
 * @param {string} coreUrl - The core URL to which the username will be appended.
 * @param {string} [contributor_user_name] - Optional username to override the default user name.
 * @returns {string} The generated URL path with the username.
 */
export const generatePathUrl = (coreUrl, contributor_user_name) => {
  const { name, contributor_name } = StorageService.getUser();

  const url = contributor_user_name || contributor_name || name || "username";

  return generatePath(coreUrl, {
    username: url?.replace(/\s/g, "")?.toLowerCase(),
  });
};

/**
 * Converts a file to a Base64 encoded string.
 *
 * @param {File} file - The file to be converted.
 * @returns {Promise<string>} A promise that resolves to a Base64 encoded string of the file.
 */
export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result); // Base64 string
    reader.onerror = (error) => reject(error);
  });
};

/**
 * Downloads a file from a Base64 string.
 *
 * @param {string} base64String - The Base64 encoded string representing the file.
 * @param {string} fileName - The desired name for the downloaded file.
 */
export const handleBase64Download = (base64String, fileName) => {
  // Create a Blob from the Base64 string
  const base64Parts = base64String.split(",");
  const contentType = base64Parts[0].split(":")[1].split(";")[0]; // Extract MIME type
  const byteCharacters = atob(base64Parts[1]); // Decode Base64
  const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: contentType });

  // Create a Blob URL
  const url = URL.createObjectURL(blob);

  // Create a temporary <a> element and trigger download
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName; // The desired file name
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();

  // Cleanup
  document.body.removeChild(a);
  URL.revokeObjectURL(url); // Release memory
};
